.background-img-div {
    background-image: url("../assets/hero.png");
    background-position: top right;
    background-repeat: no-repeat;
}

@media (max-width: 768px) {
    .background-img-div {
        background-size: contain;
    }
}