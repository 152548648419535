@keyframes highlightBtnFadeIn {
    0% {
        opacity: 1;
        background-color: #006ADC; /* Starting color */
    }
    50% {
        background-color: #4C3BCF; /* Intermediate highlight color */
    }
    100% {
        opacity: 1;
        background-color: #006ADC; /* Ending color, same as the starting color */
    }
}

.css-btn{
    animation: highlightBtnFadeIn 3s ease-out infinite;
    -webkit-animation: highlightBtnFadeIn 3s ease-out infinite;
}

.section-bg{
    animation: highlightBtnFadeIn 3s ease-out infinite;
}