@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.worksans {
  font-family: "Work Sans", sans-serif;
}


@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}

:root {
  --black-gradient: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
  --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

* {
  scroll-behavior: smooth;
}

.subheading-gradient {
  /* background: linear-gradient(to bottom right, #ffffff 0%, #757575 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: white;
}

.footer {
  padding: 1rem;
  margin-top: auto;
  border-top: 1px solid #2c2735;
}

.custom-gradient {
  background: linear-gradient(to bottom, #d2d2d2 0%, #FFFFFF 100%);
}

.text-gradient {
  /* background: linear-gradient(94.34deg, #FFFFFF 0%, #9BD0F5 100%),
  linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text; */
  color: #0070BF; background-image: -webkit-linear-gradient(45deg, #0070BF 6%, #1a1f21 84%); background-clip: text; -webkit-background-clip: text; text-fill-color: transparent; -webkit-text-fill-color: transparent;
}
.text-gradient-underline {
  background: linear-gradient(94.34deg, #FFFFFF 0%, #9BD0F5 100%), linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: underline;
  background-clip: text;
}


.btn-animate{
  animation: scale-animation 1.5s infinite;
}

@keyframes scale-animation {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fade-in 0.5s ease-in-out;
}

.card-bg{
  border: 1px solid #fff;
  border-radius: 6px;
  background: #e7e7e7;
  /* background: linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient(        var(--angle),        #54B848,        #036BC8      ) border-box; */
  animation: 8s rotate linear infinite;
  -webkit-animation: 8s rotate linear infinite;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.card-bg:hover{
  background: linear-gradient(#1b1922, #1b1922) padding-box, linear-gradient(
    var(--angle),
    #54B848,
    #036BC8
  ) border-box;
  animation: 8s rotate linear infinite;

}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

@keyframes fade {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

.animate-fade {
  animation: fade 1s infinite;
}



.text-gradient-2 {
  /* background: linear-gradient(135deg, #FFFFFF 0%, #71717A 100%),
  linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent; */
  color: white;
}

.bg-hover-gradient {
  background: rgba(24, 24, 27, 0.5);
}

.bg_three-dots_active {
  background: linear-gradient(135deg, #665DCD 0%, #5FA4E6 44.76%, #D2AB67 100%);
}

.bg_three-dots {
  background: rgba(51, 51, 51, 1);
}

.profile-image {
  width: 100%;
  max-height: 100px;
  object-fit:contain;
  position: relative;
  z-index: 2;
  mask-image: linear-gradient(rgba(0, 0, 0, 1) 70%, transparent);
}

.background-image {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(94.34deg, #ffffff 0%, #9bd0f5 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.bg-blue-gradient {
  background: linear-gradient(
    157.81deg,
    #def9fa -43.27%,
    #bef3f5 -21.24%,
    #9dedf0 12.19%,
    #7de7eb 29.82%,
    #5ce1e6 51.94%,
    #33bbcf 90.29%
  );
}

.bg-black-gradient {
  background: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}

.bg-black-gradient-2 {
  background: linear-gradient(
    -168.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}

.bg-expertise_type {
  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.9) 35%, rgba(0, 0, 0, 0) 100%) 
}

.bg-gray-gradient {
  background: linear-gradient(
    153.47deg,
    rgba(255, 255, 255, 0) -341.94%,
    #14101d 95.11%
  );
}

.bg-discount-gradient {
  background: linear-gradient(125.17deg, #272727 0%, #11101d 100%);
}

.box-shadow {
  box-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

.sidebar {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.text-gradient-2 {
  /* background: linear-gradient(135deg, #FFFFFF 0%, #71717A 100%),
  linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700; */
  color: white;
}


@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.feature-card:hover {
  background: var(--black-gradient);
  box-shadow: var(--card-shadow);
}

.feedback-container .feedback-card:last-child {
  margin-right: 0px;
}

.feedback-card {
  background: transparent;
}

.feedback-card:hover {
  background: var(--black-gradient);
}

.blue__gradient {
  background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214d76 100%);
  filter: blur(123px);
}

.pink__gradient {
  background: linear-gradient(90deg, #f4c4f3 0%, #fc67fa 100%);
  filter: blur(900px);
}

.white__gradient {
  background: rgba(255, 255, 255, 0.6);
  filter: blur(750px);
}

/* Join Telegram Button In Course Card  */

.join-Telegram{
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 5px;
  color: #fff;
}

.join-Telegram svg{
  font-size: 20px;
  color: #3eabd9;
  background-color: #fff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

/* CourseCards Different Dimension */

.courseCard > div:nth-child(4) {
  width: calc((100% / 5) * 3);
}

.courseCard > div:nth-child(5) {
  width: calc((100% / 5) * 2);
}

/* SubHeadings of the page */

.subheading-color{
    font-weight: 500;
    background: linear-gradient(#FFFFFF, #71717A);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.custom-progress-bar {
  height: 5px;
  margin: 8px 0;
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 10px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background: linear-gradient(90deg, #FFFFFF 0%, #51ABEC 100%);
}



.carousel {
  width: 370px;
  margin-right: 10px;
  margin-left: 10px;
}
.bg_three-dots_active {
  background: linear-gradient(135deg, #665dcd 0%, #5fa4e6 44.76%, #d2ab67 100%);
}
.bg_three-dots {
  background: rgba(51, 51, 51, 1);
}

/* Expertise.css START */
.section {
  color: var(--gradient-bar);
  margin-bottom: 1rem;
}

.expert-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* gap: 40px; */
}

.background-wrapper {
  position: relative;
}

.background-image {
  width: 100%;
  max-height: 150px;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.dark-overlay {
  position: relative;
}

.dark-overlay::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(to top, rgba(6, 3, 14), rgba(0, 0, 0, 0));
  pointer-events: none;
  z-index: 3;
}

.profile-div:hover {
  background-color: #18181B;
}

.info {
  padding: 0 1.5rem 1rem;
}

.name-rating {
  justify-content: space-between;
  display: flex;
}

.name-role {
  justify-content: space-between;
  align-items: center;
}

.name-role h3 {
  margin: 0;
  font-size: 1.3rem;
}

.name-role p {
  margin-top: 3px;
  font-size: 0.8rem;
  color: #C6CDD5;
}

.experience-followers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0rem;
  position: relative;
  text-align: center;
}

.experience-followers span{
  font-weight:lighter;
  color: #C6CDD5;
}

.experience-followers::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 1.5px;
  background-color: #fff;
  left: 50%;
  transform: translateX(-50%);
}

/* .prize-subscription {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  align-items: center;
}

.prize-subscription a {
  font-size: 1rem;
  box-shadow: inset 0 0 0 0 #fff;
  border: 1.2px solid #fff;
  border-radius:10px;
  background-color: #fff;
  color: #000;
  text-decoration: none;
  margin: 0 -.25rem;
  padding: 1rem 1rem;
  transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
  -webkit-border-radius:10px;
  -moz-border-radius:10px;
  -ms-border-radius:10px;
  -o-border-radius:10px;
}

.prize-subscription a:hover {
  box-shadow: inset 200px 0 0 0 #000;
  color: #fff;
} */

.prize {
  font-size: 1.2rem;
}

.prize span{
  font-size: 2rem;
}

.expertise-Bg {
  background-image: url('./assets/Div.png'), url('./assets/middle-left.png');
  background-repeat: no-repeat, no-repeat;
  background-position: 25rem -20rem, -20rem 75rem;
  background-size: auto, 45rem;
}

@media (max-width: 1060px) {
  .expert-grid {
    gap: 14px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-top: 2rem;
  }

  .info {
    padding: 0.5rem;
  }

  .name-role h3 {
    font-size: 14px;
    line-height: 10px;
  }

  .name-role p {
    font-size: 10px;
  }

  .name-rating {
    font-size: 10px;
  }

  .experience-followers span {
    font-size: 10px;
  }

  .experience, .followers {
    font-size: 10px;
  }

  button {
    font-size: 8px;
  }

  .telegram {
    width: 14px;
    height: 14px;
  }

  .prize-subscription {
    margin-top: 0.5rem;
  }

  .expertise-Bg {
    background-position: -15rem -25rem, -25rem 40rem;
  }
}
/* Expertise.css END  */

/* Subscription RA-detail START */
.carousel {
  overflow: hidden;
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease;
}

.carousel-slide {
  flex: 0 0 auto;
  width: 370px;
  padding: 1px;
  margin-right: -10px;
  cursor: pointer;
}




/* ExpertCard Background Positioning */
.subscription-RA-bg {
  background-image: url("./assets/userBck.png");
  background-repeat: no-repeat;
  background-position: 32vw 1vh;
  background-size: 55rem;
  z-index: 10;
}

.subscription-RA-img {
  mask-image: linear-gradient(rgba(0, 0, 0, 1) 70%, transparent);
}

@media (max-width: 1060px) {
  .subscription-RA-bg {
      background-size: 22rem;
      background-position: 22vw 6vh;
  }
}


.carousel-container-desktop {
  overflow: hidden;
  width: 100%;
}

.carousel-desktop {
  display: flex;
  align-items: center;
  overflow: hidden; /* Hide overflowing content */
}

.carousel-inner-desktop {
  display: flex;
  transition: transform 0.5s ease;
}

.carousel-slide-desktop {
  flex: 0 0 auto;
  width: 300px; /* Adjust as needed */
  margin-right: 10px; /* Adjust spacing between slides */
}

.carousel-btn-desktop {
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.carousel-btn-desktop.prev {
  margin-right: 10px;
}

.carousel-btn-desktop.next {
  margin-left: 10px;
}

.basic-div:hover button {
  background-color: white;
  transition-duration: 300ms;
  color: black;
}

.standard-div:hover button {
  background-color: white;
  transition-duration: 300ms;
  color: black;
}

.basic-div:not(:hover) button {
  background-color: transparent;
  transition-duration: 300ms;
  color: white;
}

.standard-div:not(:hover) button {
  background-color: transparent;
  color: white;
  transition-duration: 300ms;

}

  /* width: 100%;
  display: inline-block;
} */
/* Subscription RA-detail END */

.marquee {
  align-items: center;
  white-space: nowrap;
  animation: marquee 25s linear infinite;
}

.bg-subscription{
  background: linear-gradient(110.81deg, rgba(24, 24, 27, 0.4) 41.99%, rgba(255, 255, 255, 0.1) 134.07%);
}

.bg-sub{
  background: linear-gradient(110.81deg, rgba(24, 24, 27, 0.4) 41.99%, rgba(255, 255, 255, 0.1) 134.07%);

}

.bg-glass {
  background: rgba(24, 24, 27, 50);
  border-radius: 10px;
  backdrop-filter: blur(6px);
  z-index: -2;
  -webkit-border-radius:;
  -moz-border-radius:;
  -ms-border-radius:;
  -o-border-radius:;
}

.same-height {
  height: 300px; /* Adjust this value as needed */
}



.slick-slide {
  padding: 0 10px; /* Adjust the value as needed to create gaps between slides */
}

.slick-list {
  margin: 0 -10px; /* Negative margin to offset the padding and maintain alignment */
}

.mask-img {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.1) 30%, transparent);
  z-index: 2; /* Higher z-index to ensure it appears above the expert image */
  position: absolute; /* Ensure it's positioned correctly over the expert image */
  width: 100%;
  height: 100%;
}





.discount-badge {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #4caf50;
  color: white;
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 0 5px 0 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 0 5px 0 20px;
  -moz-border-radius: 0 5px 0 20px;
  -ms-border-radius: 0 5px 0 20px;
  -o-border-radius: 0 5px 0 20px;
}


.bg-subs {
  border: 2px solid #4caf50;
}


@keyframes marquee {
  0% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(-50%);
  }
}

