.subscription-RA-bg {
  background-image: url("../../assets/userBck.png");
  background-repeat: no-repeat;
  background-position: 28vw 1vh;
  background-size: 55rem;
}

.subscription-RA-img {
  mask-image: linear-gradient(rgba(0, 0, 0, 1) 70%, transparent);
}

@media (max-width: 1060px) {
  .subscription-RA-bg {
    background-size: 22rem;
    background-position: 22vw 6vh;
  }
}

.basic-div:hover button {
  background-color: white;
  color: white;
}

.standard-div:hover button {
  background-color: white;
  color: black;
}

.basic-div:not(:hover) button {
  background-color: #baddfc;
  border: 2px #004785;
  color: #1a94ff;
}

.standard-div:not(:hover) button {
  background-color: #0081F1;
  border: 1px white;
  color: white;
}

@keyframes wave-gradient {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}

.wave-gradient {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.3));
  background-size: 200% 100%;
  animation: wave-gradient 5s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes gradient-rotation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.gradient-bg-animate {
  background: linear-gradient(270deg, #1e3a8a, #3b82f6, #06b6d4, #1e3a8a);
  background-size: 600% 600%;
  animation: gradient-rotation 8s ease infinite;
}
